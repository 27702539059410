import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import EmailIllustration from "../../../svg/ComparisonResultPageIllustrations/emailmarketingservices.svg";
import ConstantContactSVG from "../../../svg/ComparisonResultGridLogos/emailmarketingservices/constantcontact.svg";
import MoosendSVG from "../../../svg/ComparisonResultGridLogos/emailmarketingservices/moosend.svg";
import MailChimpSVG from "../../../svg/ComparisonResultGridLogos/emailmarketingservices/mailchimp.svg";
import ConvertKitSVG from "../../../svg/ComparisonResultGridLogos/emailmarketingservices/convertkit.svg";
import HubSspotSVG from "../../../svg/ComparisonResultGridLogos/emailmarketingservices/hubspot.svg";
export const pageMetaData = {
  pageTitle: "Compare Business Email Marketing Services",
  pageDescription: "Power Your Email Communications From $10 Per Month",
  pageImagePath: "/businessinsurance.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Price"
      }, {
        index: 2,
        text: "Free Plan"
      }, {
        index: 3,
        text: "Free Trial Period"
      }, {
        index: 4,
        text: "Target Industry"
      }, {
        index: 5,
        text: "Customer Support"
      }, {
        index: 6,
        text: "Pros"
      }, {
        index: 7,
        text: "Cons"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Constant Contact",
          subText: "",
          imageKey: "constant-contact"
        }
      }, {
        index: 2,
        text: "20",
        isPriceCell: true,
        showRating: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "cross"
      }, {
        index: 4,
        text: "60 Days"
      }, {
        index: 5,
        text: "Event Management, PR Agencies"
      }, {
        index: 6,
        text: "Phone, Email, Support Tickets"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Event management features"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "Not many automations options"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://constant-contact.ibfwsl.net/c/1323808/348648/3411",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Constant Contact",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Moosend",
          subText: "",
          imageKey: "moosend"
        }
      }, {
        index: 2,
        text: "10",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "Forever"
      }, {
        index: 5,
        text: "eCommerce Companies, Agencies, Publishers"
      }, {
        index: 6,
        text: "Knowledge Base, Phone, Chat, Email"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Balls-to-the-wall automation, next-level personalization, and list segmentation options"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "No pop-up based opt-in forms"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://moosend.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Moosend",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Mailchimp",
          subText: "",
          imageKey: "mailchimp"
        }
      }, {
        index: 2,
        text: "10",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "Forever"
      }, {
        index: 5,
        text: "Agencies"
      }, {
        index: 6,
        text: "Knowledge Base, Email, Chat"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Ready to use campaign templates."
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "Not affiliate marketing-friendly, hidden fees"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://mailchimp.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Mailchimp",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "ConvertKit",
          subText: "",
          imageKey: "convertkit"
        }
      }, {
        index: 2,
        text: "29",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "cross"
      }, {
        index: 4,
        text: "14 Day"
      }, {
        index: 5,
        text: "Bloggers, Content Creators"
      }, {
        index: 6,
        text: "Email, Live Chat"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Easy-as-pie rule builder"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "Pricey for what it can offer"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://convertkit.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to ConvertKit",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "HubSpot",
          subText: "",
          imageKey: "hubspot"
        }
      }, {
        index: 2,
        text: "50",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        text: "Forever"
      }, {
        index: 5,
        text: "Small & Medium Sized Businesses"
      }, {
        index: 6,
        text: "Phone, Email, Knowledge Base"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Higher starting price"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "Personalization and Automation"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.hubspot.com/products/get-started",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to HubSpot",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare business email marketing services`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<EmailIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="EmailIllustration" />, <EmailIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="EmailIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Waiting on copy from Steady Content as I can't think of anything relevant to say so saying nothing at all is probably best.`}</p>
      </ComparisonResultPageTextWithImageSection>
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <ConstantContactSVG className="w-full" imagekey="constant-contact" mdxType="ConstantContactSVG" />
  <MoosendSVG className="w-full" imagekey="moosend" mdxType="MoosendSVG" />
  <MailChimpSVG className="w-full" imagekey="mailchimp" mdxType="MailChimpSVG" />
  <ConvertKitSVG className="w-full" imagekey="convertkit" mdxType="ConvertKitSVG" />
  <HubSspotSVG className="w-full" imagekey="hubspot" mdxType="HubSspotSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="business" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked`}</h2>
        <h3>{`Comparing Services by Their Ratings`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="email marketing services" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      